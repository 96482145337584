<template>
    <div class="payCheckWrap">
        <div class="payCheckText">信息已提交</div>
        <div class="payBtnModule">
          <div class="yycx" @click="backSY">预约出行</div>
        </div>
    </div>
</template>
<style scoped>
.payCheckWrap{
    width: 7.5rem;
    height: 100vh;
    background: #f8f8f8;
}
.payCheckText{
    width: 7.5rem;
    height: 4.9rem;
    text-align: center;
    line-height: 4.9rem;
    font-size: 0.32rem;
    color: #333333;
    margin-bottom: 0.65rem;
}
.payBtnModule{
    width: 7.5rem;
    height: 0.85rem;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
}

.yycx{
    width: 2.8rem;
    height: 0.85rem;
    font-size: 0.32rem;
    color: #f8f8f8;
    text-align: center;
    line-height: 0.85rem;
    border-radius: 0.43rem;
    background-image: linear-gradient(to right,#ee8d43,#f1ab4b);
}
</style>
<script>
export default {
  name: "",
  props: {},
  data() {
    return {
      
    };
  },
  components: {},
  created() {},
  mounted() {},
  beforeMount() {},
  beforeDestroy() {},
  methods: {
    backSY(){
      let backIndex = localStorage.getItem('backIndex')
      let path ="/h/n/yntrip"; 
      if(backIndex=='1'){
        path = "/h/n/yntripOne"
      }else if(backIndex=='2'){
        path = "/h/n/yntripTwo"
      }else if(backIndex == 'free') {
        path = "/h/n/ynTripFree"
      }
      this.$router.push({
          path: path,
          query: { scroll:1 },
        });
    }
  },
};
</script>